* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    box-sizing: border-box;
    // font-family:;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

a,
ol,
ul {
    list-style: none;
    list-style-type: none;
    text-decoration: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.3;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

p {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-family: var(--playsans-neutral);
}

a {
    color: unset;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: unset;
    }
}



body {
    font-family:var(--playsans-neutral);
    color:var(--primary);
    background-color: black;
    line-height:1.3;
    font-weight:400;
    font-size:16px;
    position: relative;
    z-index: 1;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: black;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border-radius: 20px;
    }
}

body::before {
    content: "";
    background-image: url("/assets/img/overlay.jpg");
    opacity: 31.4%;
    background-repeat: repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;
    mix-blend-mode: hard-light;
}

body::after {
    content: "";
    background-image: url("/assets/img/body-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}


.blog-home {
    background-position: top left !important;
}
