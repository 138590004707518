
.banner-area {
    padding: 10px 0px;
}
.banner-wrapper {
    position: relative;
    z-index: 1;
    .banner-bg-shape {
        position: absolute;
        max-width: 70%;
        top: 0;
        right: 0;
        z-index: -1;
        animation: zoomInOut 5s linear infinite alternate;
    }
}
.banner-star-shape {
    position: absolute;
    content: "";
    top: 10px;
    right: 0;
    animation: rotate 2s linear infinite;
}
.banner-cercle-shape {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    animation: spinAndScale 2s linear infinite;
}
.banner-inner {
    border: 2px solid var(--primary);
    padding: 10px 18px;
    padding-bottom: 0;
    padding-right: 0;
    clip-path: polygon(0 0, calc(100% - 130px) 0, 100% 130px, 100% 100%, 130px 100%, 0 calc(100% - 130px));
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        background-color: var(--primary);
        top: 0;
        left: 0;
        clip-path: polygon(100% 0, 0 0, 0 100%);
    }
}
.border-bottom-angle,
.border-top-angle {
    position: absolute;
    content: "";
    width: 130px;
    height: 130px;
    clip-path: polygon(100% 100%, 100% calc(100% - 3px), 3px 0, 0 0);
    background-color: var(--primary);
}
.border-top-angle {
    top: 0px;
    right: 0px;
}
.border-bottom-angle {
    left: 0;
    bottom: 0;
}

.banner-text-image {
    padding-right: 10px;
    @media screen and (min-width: 991px) {
        img {
            height: calc(100vh - 288px);
        }
        svg {
            width: calc((100vh - 288px)*2.38); 
        }
    }
    svg {
       max-width: 100%;
       max-height: 100%;
       margin-top: 15px;
       &:last-child {
        display: none;
       }
    }
}

// Responsive 
@media screen and (max-width: 991px) {
    .border-bottom-angle, 
    .border-top-angle {
        width: 68px;
        height: 68px;
    }
    .banner-inner {
        clip-path: polygon(0 0, calc(100% - 68px) 0, 100% 68px, 100% 100%, 68px 100%, 0 calc(100% - 68px));
    }
    .banner-cercle-shape,
    .banner-star-shape {
        width: 30px;
    }
}

// Nivesting Style Start here ==========
.investing-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 5px;
    position: relative;
    .dots-group-shape {
        max-width: 110px;
        display: flex;
        align-items: flex-end;
        padding: 0.5rem;
        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        background-color: var(--primary);
        bottom: -1px;
        right: 0;
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
}

.investing-left {
    position: absolute;
    bottom: -1px;
    left: -19px;
    img {
        // animation: downMovingReverse 1s linear infinite alternate;
    }
    h6 {
        font-weight: 400;
        font-size: 14px;
        line-height: 122.5%;
        text-transform: uppercase;
        color: #FFBA08;
        transform: rotate(-90deg) translateX(37px);
    }
}


.investing-right {
    max-width: 974px;
    width: 100%;
    text-align: right;
    position: relative;
    margin-top: 1rem;
    .plus-shape {
        position: absolute;
        left: 0;
        top: 0;
        animation: rotate 1s linear infinite;
    }
    .investing-angle {
        position: absolute;
        content: "";
        width: 85px;
        height: 85px;
        background-color: var(--primary);
        top: 0;
        left: 0;
        clip-path: polygon(calc(100% - 2px) 0, 100% 1%, 1% 100%, 0 calc(100% - 2px));
    }
}
.investing-right-inner {
    border-left: 2px solid var(--primary);
    border-top: 2px solid var(--primary);
    padding: 30px 27px;

    clip-path: polygon(calc(10% - 12px) 0, 100% 0, 100% 100%, 0 100%, 0 calc(100% - 39px));
    h4 {
        max-width: 500px;
        margin-left: auto;
        text-align: right;
        font-weight: 400;
        font-size: 2rem;
        line-height: 97%;
        text-transform: uppercase;
    }
}

// Responsive 
@media screen and (max-width: 1350px) {
    .investing-right {
        max-width: 760px;
    }
    .investing-right-inner {
        clip-path: polygon(calc(10% - -8px) 0, 100% 0, 100% 100%, 0 100%, 0 calc(100% - 39px));
    }
}
@media screen and (max-width: 991px) {
    .investing-right-inner h4 {
        max-width: 450px;
        font-size: 34px;
        line-height: 1.1;
    }
    .investing-right {
        max-width: 535px;
    }
    .investing-right-inner {
        clip-path: polygon(calc(10% + 31px) 0, 100% 0, 100% 100%, 0 100%, 0 calc(100% - 51px));
    }
}
@media screen and (max-width: 767px) {
    .banner-text-image {
        svg {
           display: none;
           max-width: 1768px;
           max-height: 100%;
           margin-top: 15px;
           &:last-child {
            display: block;
           }
        }
    }
    .investing-right {
        max-width: 270px;
        .investing-angle {
            width: 50px;
            height: 50px;
        }
    }
    .investing-right-inner {
        clip-path: polygon(calc(10% + 23px) 0, 100% 0, 100% 100%, 0 100%, 0 calc(100% - 80px));
        h4 {
            max-width: 207px;
            font-size: 20px;
            line-height: 1.1;
        }
    }
}
@media screen and (max-width: 575px) {
    .investing-left {
        h6 {
            font-size: 10px;
            transform: rotate(-90deg) translateX(20px) translateY(-5px);
        }
        img {
            width: 40px;
        }
    }
    .investing-right {
        max-width: 250px;
    }
    .investing-right-inner {
        padding: 20px;
        clip-path: polygon(calc(10% - -24px) 0, 100% 0, 100% 100%, 0 100%, 0 calc(100% - 58px));
        h4 {
            font-size: 20px;
        }
    }
    .investing-wrapper {
        .dots-group-shape {
            display: none;
        }
    }
    .banner-inner {
        padding: 10px 12px;
        padding-bottom: 0;
        padding-right: 0;
    }
    .investing-left {
        left: -12px;
    }
}



// Disover Area Style Start Here =======================
.discover-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 75px 0px;
    padding-left: 150px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: calc(100% + 20px);
        height: 2px;
        top: 0;
        left: -18px;
        background-color: var(--primary);
    }
    > img {
        position: absolute;
        z-index: 1;
        right: 18px;
        bottom: 18px;
        animation: downMoving 1s infinite;
    }
}
.discover-left {
    max-width: 755px;
    width: 100%;
    padding-right: 20px;
    h4 {
        font-size: 26px;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: var(--playsans-bulky);
        span {
            display: block;
            margin-left: 35px;
        }
    }
    p {
        font-size: 22px;
        line-height: 1.2;
        font-weight: 400;
        margin-top: 35px;
        span {
            display: inline-block;
            width: 35px;
        }
    }
}
.discover-right {
    max-width: 755px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
        max-width: 100%;
        max-height: 100%;
    }
    &::before {
        position: absolute;
        content: "";
        width: 2px;
        height: calc(100% + 150px);
        top: -75px;
        left: 0;
        background-color: var(--primary);
    }
    &::after {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        background-color: var(--primary);
        bottom: -24%;
        left: 0;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
    }
}

// Responsive 
@media screen and (max-width: 1350px) {
    .discover-right {
        &::before {
            height: 200%;
        }
    }
}
@media screen and (max-width: 1341px) {
    .discover-right {
        &::after {
            bottom: -28%;
        }
    }
}
@media screen and (max-width: 1199px) {
    .discover-right {
        &::before {
            height: 200%;
        }
        &::after {
            display: none;
        }
    }
    .discover-wrapper {
        padding-left: 80px;
    }
}
@media screen and (max-width: 991px) {
    .discover-wrapper {
        padding-left: 20px;
    }
    .discover-left {
        p {
            font-size: 18px;
            margin-top: 30px;
        }
    }
}
@media screen and (max-width: 767px) {
    .discover-wrapper {
        padding: 0px 0px;
        padding-left: 0px;
        flex-direction: column;
    }
    .discover-left {
        max-width: 755px;
        width: 100%;
        padding-right: 20px;
        padding: 20px 20px 20px 10px;
        position: relative;
        h4 {
            font-size: 20px;
        }
        &::before {
            position: absolute;
            content: "";
            width: 16px;
            height: 16px;
            background-color: var(--primary);
            bottom: 0;
            left: -18px;
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }
    }
    .discover-right {
        max-width: 100%;
        padding: 20px 10px 20px 40px;
        &::before {
            width: calc(100% + 40px);
            top: 0;
            left: -20px;
            height: 1px;
        }
    }
}
@media screen and (max-width: 575px) {
    .banner-inner {
        border: 1px solid var(--primary);
    }
    .border-bottom-angle, 
    .border-top-angle {
        clip-path: polygon(100% 100%, 100% calc(100% - 2px), 2px 0, 0 0);
    }
    .discover-wrapper {
        >img {
            display: none;
        }
    }
    .investing-right-inner {
        border-left: 1px solid var(--primary);
        border-top: 1px solid var(--primary);
    }
    .investing-right .investing-angle {
        clip-path: polygon(calc(100% - 1px) 0, 100% 1%, 1% 100%, 0 calc(100% - 1px));
    }
    .discover-wrapper {
        &::before {
            height: 1px;
        }
    }
}