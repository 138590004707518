
.header-area {
    padding: 8px 0px;
    // border-bottom: 4px solid var(--primary);
}

.header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .no-navbar {
        display: none;
    }
}

.logo {
    max-width: 102px;
    max-height: 73px;
    width: 100%;
    height: 100%;
}

.main-menu {
    ul {
        display: flex;
        align-items: center;
        gap: 82px;
        li {
            a {
                font-family: 'PolySans Neutral';
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
                color: var(--primary);
                text-transform: uppercase;
                padding: 5px 0px;
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 2px;
                    background-color: var(--primary);
                    right: 0;
                    bottom: 0;
                    transition: all .3s ease-in-out;
                }
                &:hover {
                    &::before {
                        width: 100%;
                        right: auto;
                        left: auto;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 767px) {
    .main-menu {
        background-image: none !important;
    }
}

.mobile-menu-bg,
.mobile-menu-footer-wrap {
    display: none;
}

// Humburger 
.hamburger {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: none;
	padding: 0;
    .line {
        fill: none;
        stroke: var(--primary);
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }
    .line2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
    }
    .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }

    &.opened {
        .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
        }
        .line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
            stroke-width: 6;
        }
        .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
        }
    }
}


// Responsive 
@media screen and (max-width: 991px) {
    .main-menu {
        ul {
            gap: 40px;
        }
    }
}
@media screen and (max-width: 767px) {
    .header-area {
        position: relative;
        border-width: 2px;
    }
    .main-menu {
        position: absolute;
        content: "";
        width: 100vw;
        height: calc(100vh - 91px);
        left: 0;
        top: 91px;
        z-index: 999;
        padding: 10px;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        transition: all .3s ease-in-out;
        visibility: hidden;
        &::-webkit-scrollbar {
            width: 0px;
            height: 100%;
        }
        &::-webkit-scrollbar-track {
            background-color: #fafafa;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--primary);
            border-radius: 20px;
        }
        ul {
            display: block;
            width: 100%;
            padding: 0px 10px;
            li {
                border-bottom: 1px solid var(--primary);
                display: block;
                text-align: center;
                transform: translateY(50px);
                opacity: 0;
                visibility: hidden;
                &:last-child {
                    border-bottom: none;
                }
                a {
                    padding: 20px 0px;
                    display: block;
                    &::before {
                        display: none;
                    }
                }
            }
        }

        &.mobile-menu-active {
            opacity: 1;
            visibility: visible;
            ul {
                li {
                    transform: translateY(0px);
                    opacity: 1;
                    visibility: visible; 
                    &:nth-child(1) {
                        transition-duration: .4s;
                    }
                    &:nth-child(2) {
                        transition-duration: .6s;
                    }
                    &:nth-child(3) {
                        transition-duration: .8s;
                    }
                    &:nth-child(4) {
                        transition-duration: 1s;
                    }
                    &:nth-child(5) {
                        transition-duration: 1.2s;
                    }
                }
            }
            .mobile-menu-footer-wrap{
                > img {
                    transform: translateY(0px);
                    opacity: 1;
                    visibility: visible; 
                    transition-duration: 1.4s;
                }
            }
            .mobile-menu-footer {
                transform: translateY(0px);
                    opacity: 1;
                    visibility: visible; 
                    transition-duration: 1.6s;
            }
        }
    }
    .mobile-menu-bg {
        display: block;
        position: absolute;
        content: "";
        width: 100vw;
        height: calc(100vh - 103px);
        bottom: 0;
        left: 0;
        z-index: -1;
        display: none;
    }
    .hamburger {
        display: flex;
    }
    .mobile-menu-footer-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 50px;
        position: relative;
        padding-top: 25px;
        &::before {
            position: absolute;
            content: "";
            width: 1120%;
            height: 1px;
            background-color: var(--primary);
            top: 0;
            left: -20px;
        }
        > img {
            max-width: 450px;
            width: 100%;
            transform: translateY(50px);
            opacity: 0;
            visibility: hidden;
        }
    }
    .mobile-menu-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        transform: translateY(50px);
        opacity: 0;
        visibility: hidden;
        img {
            width: 140px;
        }
        p {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: var(--primary);
        }
    }
    .menu-body {
        overflow: hidden !important;
        position: relative;
        z-index: 1;
    }
}