
.experince-wrapper {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 142px;
        height: 142px;
        bottom: 0;
        right: 0;
        z-index: 1;
        background-color: var(--primary);
        clip-path: polygon(calc(100% - 2px) 0, 100% 0, 100% 1px, 1px 100%, 0 100%, 0 calc(100% - 2px));
    }
    .shape {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        z-index: 1;
        width: 88px;
        height: 88px;
        overflow: hidden;
        border: 2px solid var(--primary);
        &::before {
            position: absolute;
            content: "";
            width: calc(100% - 24px);
            height: calc(100% - 24px);
            z-index: -1;
            top: 0;
            left: 0;
            border: 12px solid var(--primary);
        }
        img {
            animation: rotate 10s linear infinite;
        }
    }
}
.experince-wrap {
    border: 2px solid var(--primary);
    display: flex;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 142px), calc(100% - 142px) 100%, 0 100%, 0% 50%);
}


.experince-thumb {
    padding: 40px;
    max-width: 750px;
    width: 100%;
    border-right: 2px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        max-width: 100%;
        max-height: 100%;
    }
}

.experince-content {
    padding: 40px 90px 40px;
    position: relative;
    &::after {
        width: 16px;
        height: 16px;
        background-color: var(--primary);
        position: absolute;
        content: ""; 
        bottom: 0px;
        left: 0;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
    }
    .title {
        display: flex;
        align-items: center;
        gap: 40px;
        img {
            animation: downMoving 2s linear infinite;
        }
        h2 {
            font-weight: 400;
            font-size: 50px;
            line-height: 97%;
            text-transform: uppercase;
            span {
                display: block;
                margin-left: 148px;
            }
        }
    }
    .content {
        margin-top: 130px;
        display: flex;
        align-items: center;
        gap: 18px 11px;
        flex-wrap: wrap;
        li {
            display: flex;
            align-items: center;
            gap: 11px;
            a {
                font-weight: 400;
                font-size: 22px;
                line-height: 110%;
                color: #FFBA08;
            }
            svg {
                width: 23px;
                height: 30px;
            }
        }
    }
}

// Responsive 
@media screen and (max-width: 1199px) {
    .experince-content {
        padding: 40px;
        .title {
            h2 {
                font-size: 30px;
                span {
                    margin-left: 90px;
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .experince-wrapper{
        overflow: hidden;
        &::before {
            width: 50px;
            height: 50px;
        }
        .shape {
            &::before {
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                border: 10px solid var(--primary);
            }
        }
    }
    .experince-wrap {
        flex-direction: column;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%, 0% 50%);
        .shape {
            width: 50px;
            height: 50px;
            overflow: hidden;
        }
    }
    .experince-thumb {
        padding: 40px;
        border-right: none;
        max-width: 100%;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: calc(100% + 80px);
            height: 1px;
            background-color: var(--primary);
            left: -40px;
            bottom: 0;
        }
    }
    .experince-content {
        position: relative;
        .content {
            margin-top: 50px;
        }
    }
}
@media screen and (max-width: 575px) {
    .experince-thumb {
        padding: 20px;
    }
    .experince-wrap {
        .shape {
            width: 30px;
            height: 30px;
        }
    }
    .experince-content {
        padding: 20px;
        padding-right: 40px;
        .title {
            gap: 10px;
            img {
                width: 27px;
            }
            h2 {
                font-size: 24px;
                line-height: 1.2;
                span {
                    margin-left: 60px;
                }
            }
        }
        .content {
            margin-top: 30px;
            li {
                gap: 10px;
                a {
                    font-size: 16px;
                }
                svg {
                    width: 14px;
                    height: auto;
                }
            }
        }
    }
    .experince-wrap {
        border: 1px solid var(--primary);
    }
    .experince-wrapper {
        &::before {
            clip-path: polygon(calc(100% - 1px) 0, 100% 0, 100% 1px, 1px 100%, 0 100%, 0 calc(100% - 1px));
        }
    }
}