
.team-wrapper {
    position: relative;
    .plus-shape {
        position: absolute;
        content: "";
        top: 30px;
        right: 0px;
        animation: rotate 2s linear infinite;
    }
}

.team-wrapper-inner {
    position: relative;
    border: 2px solid var(--primary);
    border-top: 0;
    padding: 50px 152px;
    clip-path: polygon(0 0, 97% 0, 100% 130px, 100% 100%, 0 100%, 0 44%);
    &::before {
        position: absolute;
        content: "";
        width: 130px;
        height: 130px;
        background-color: var(--primary);
        top: -1px;
        right: -1px;
        clip-path: polygon(0 0, 2px 0, 100% calc(100% - 2px), 100% 100%, calc(100% - 2px) 100%, 0 2px);
    }
}

.team-title {
    margin-bottom: 40px;
    position: relative;
    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 97%;
        text-transform: uppercase;
    }
    img {
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        left: -152px;
    }
}

.team-member-wrap {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 50px 20px;
    position: relative;
}
.single-team-shape-3,
.single-team-shape-4,
.single-team-shape {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.single-team-shape {
    img {
        animation: rotate 10s linear infinite;
    }
}
.team-shep-03 {
    img {
        animation: breathing 5s linear infinite;
    }
}
.single-team-shape-3 {
    img {
        animation: downMovingReverse 5s linear infinite;
    }
}
.single-team-shape-4 {
    img {
        animation: spinAndScale 10s linear infinite;
    }
}

.single-team-member {
    .thumb {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            background-color: var(--primary);
            bottom: 0;
            right: 0;
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            transition: all .4s ease-in-out;
        }
    }
    .thumb-inner {
        overflow: hidden;
        clip-path: polygon(40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%, 0 40px);
        img {
            transform: scale(1);
            transition: all .4s ease-in-out;
        }
    }
    .content {
        margin-top: 22px;
        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 130%;
            text-transform: uppercase;
            font-family: var(--playsans-bulky);
        }
        p {
            margin-top: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 122.5%;
            text-transform: uppercase;
        }
    }
    &:hover {
        .thumb {
            &::before {
                width: 20px;
                height: 20px;
            }
        }
        .thumb-inner {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.underline-middle,
.underline-bottom,
.underline-top {
    position: absolute;
    content: "";
    left: 0;
    z-index: -1;
    svg {
        max-width: 100%;
        max-height: 100%;
    }
}
.underline-top {
    top: 32%;
}
.underline-middle {
    top: 66%;
}
.underline-bottom {
    bottom: -25px;
}

// Responsive 
@media screen and (max-width: 1350px) {
    .team-wrapper-inner {
        padding: 50px;
    }
    .team-title {
        img {
            left: -50px;
        }
    }
}
@media screen and (max-width: 991px) {
    .underline-middle,
    .underline-bottom,
    .underline-top {
        display: none;
    }
    .single-team-shape-3,
    .single-team-shape-4,
    .single-team-shape {
        display: none;
    }
    .team-member-wrap {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px 20px;
    }
}
@media screen and (max-width: 767px) {
    .team-member-wrap {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px 20px;
    }
    .team-wrapper-inner {
        clip-path: polygon(0px 0px, 95% 0px, 100% 50px, 100% 100%, 0px 100%, 0px 44%);
        &::before {
            width: 50px;
            height: 50px;
        }
    }
    .team-wrapper {
        .plus-shape {
            top: 10px;
        }
    }
    .team-title {
        h2 {
            font-size: 30px;
        }
    }
}
@media screen and (max-width: 575px) {
    .team-wrapper-inner {
        padding: 20px 15px;
    }
    .team-title {
        margin-bottom: 30px;
        h2 {
            padding-left: 40px;
            font-size: 24px;
        }
        img {
            left: -15px;
            width: 27px;
        }
    }
    .single-team-member {
        .content {
            margin-top: 10px;
            h6 {
                font-size: 14px;
            }
            p {
                font-size: 10px;
            }
        }
    }
    .team-wrapper-inner {
        border: 1px solid var(--primary);
        &::before {
            clip-path: polygon(0 0, 1px 0, 100% calc(100% - 1px), 100% 100%, calc(100% - 1px) 100%, 0 1px);
        }
    }
}