
.our-approach-area {
    margin-top: 10px;
    border-top: 2px solid var(--primary);
    overflow: hidden;
    padding: 50px 0px 26px; 
    position: relative;
    .dots-group-shape {
        position: absolute;
        top: 20px;
        left: 50%;
        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.our-approach-wrapper {
    display: flex;
    align-items: center;
    gap: 95px;
}
.our-approach-content {
    max-width: 1065px;
    width: 100%;
}
.our-approach-title {
    display: flex;
    align-items: center;
    gap: 25px;
    .shpae {
        display: flex;
        svg {
            margin-left: -27px;
            animation: upsDown linear infinite alternate-reverse;
            &:first-child {
                margin-left: 0;
                animation-duration: 2s;
            }
            &:nth-child(2) {
                animation-duration: 2.1s;
            }
            &:nth-child(3) {
                animation-duration: 2.2s;
            }
            &:nth-child(4) {
                animation-duration: 2.3s;
            }
            &:nth-child(5) {
                animation-duration: 2.4s;
            }
        }
    }
    h2 {
        font-weight: 400;
        font-size: 50px;
        line-height: 97%;
        text-transform: uppercase;
        span {
            display: block;
            margin-left: 35px;
        }
    }
}
.approach-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 85px;
    margin-top: 45px;
}

.single-approach-item {
    max-width: 445px;
    width: 100%;
    .image {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            top: 0;
            left: 0;
            background-color: var(--primary);
            clip-path: polygon(98% 0, 100% 2px, 2px 100%, 0% 100%);
        }
        .image-inner {
            width: 68px;
            height: 68px;
            border: 1px solid var(--primary);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%, 0 30%);
            &::before {
                position: absolute;
                content: "";
                width: 13px;
                height: 13px;
                background-color: var(--primary);
                bottom: 0;
                right: 0;
                clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            }
        }
    }
    .content {
        margin-top: 30px;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            background-color: var(--primary);
            bottom: 0;
            right: 0;
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        }
        h4 {
            font-weight: 700;
            font-size: 26px;
            line-height: 110%;
            text-transform: uppercase;
            font-family: var(--playsans-bulky);
            padding-bottom: 6px;
            border-bottom: 2px solid var(--primary);
        }
        p {
            margin-top: 17px;
            font-weight: 400;
            font-size: 22px;
            line-height: 130%;
            letter-spacing: -0.019em;
        }
    }
    
}
.approach-items-inner {
    display: grid;
    grid-gap: 85px;
}

// https://www.youtube.com/watch?v=eiX7451Bqyg

.our-approach-map {
    max-width: 600px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    border: 2px solid var(--primary);
    padding: 13px;
    border-radius: 100%;
    position: relative;
    img {
        border-radius: 1000px;
        animation: breathing 20s linear infinite alternate;
    }
    &::after,
    &::before {
        position: absolute;
        content: "";
        width: 2px;
        height: 100%;
        background-color: var(--primary);
        left: 50%;
        transform: translateX(-50%);
    }
    &::before {
        bottom: 100%;
    }
    &::after {
        top: 100%;
    }
}
@media screen and (max-width: 1300px) {
    .our-approach-wrapper {
        gap: 40px;
    }
}

@media screen and (max-width: 1199px) {
    .our-approach-area {
        padding: 40px 0px 0px;
        .dots-group-shape {
            left: auto;
            right: 20px;
        }
    }
    .our-approach-wrapper {
        flex-direction: column;
    }
    .our-approach-map-wrap {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            position: absolute;
            content: "";
            width: calc(100% + 40px);
            height: 1px;
            background-color: var(--primary);
            top: 0;
            left: -20px;
        }
    } 
    .our-approach-map {
        position: relative;
        margin: 40px 0px;
        &::before,
        &::after {
            height: 41px;
        }
    }
}

@media screen and (max-width: 991px) {
    .approach-items {
        gap: 40px;
    }
    .approach-items-inner {
        grid-gap: 40px;
    }
}
@media screen and (max-width: 767px) {
    .approach-items {
        flex-direction: column;
    }
    .single-approach-item {
        max-width: 100%;
    }
    .our-approach-content {
        max-width: 500px;
    }
    .our-approach-title {
        h2 {
            font-size: 30px;
        }
    }
}
@media screen and (max-width: 575px) {
    .our-approach-area {
        .dots-group-shape {
            width: 70px;
            top: 10px;
            right: 10px;
        }
    }
    .our-approach-title {
        gap: 15px;
        .shpae {
            svg {
                width: 24px;
                margin-left: -10px;
            }
        }
        h2 {
            font-size: 24px;
            span {
                margin-left: 20px;
            }
        }
    }
    .single-approach-item {
        .content {
            h4 {
                font-size: 18px;
                border-width: 1px;
            }
            p {
                margin-top: 15px;
                font-size: 16px;
            }
        }
    }
    .our-approach-area {
        border-top: 1px solid var(--primary);
    }
    .our-approach-map {
        border: 1px solid var(--primary);
        &::after, 
        &::before {
            width: 1px;
        }
    }
}