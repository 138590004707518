.container,
.container-fluid {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}


.container {
    max-width: 1880px;
}