
.journal-area {
    margin-top: 10px;
    border-top: 2px solid var(--primary);
}
.jurnal-section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 0px;
    .dots-group-shape {
        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

// Responsive 
@media screen and (max-width: 767px) {
    .jurnal-section-title {
        padding: 30px 0px;
        .our-approach-title {
            h2 {
                font-size: 30px;
            }
        }
        .dots-group-shape {
            display: none;
        }
    }
}
@media screen and (max-width: 575px) {
    .jurnal-section-title {
        .our-approach-title {
            h2 {
                font-size: 24px;
                span {
                    margin-left: 20px;
                }
            }
        }
    }
}


// Items
.journal-wrapper {
    display: grid;
    //grid-template-columns: 1fr 1fr;
}
.single-journal {
    padding: 40px;
    border: 2px solid var(--primary);
    border-top: none;
    position: relative;
    .cornar-shape {
        position: absolute;
        content: "";
        width: 13px;
        height: 13px;
        background-color: var(--primary);
        top: 0;
        left: 0;
        clip-path: polygon(100% 0, 0% 100%, 0 0);
    }
    &::before {
        position: absolute;
        content: "";
        width: calc(100% - 54px);
        height: 2px;
        top: 0;
        left: 0;
        background-color: var(--primary);
    }
    &::after {
        position: absolute;
        content: "";
        width: 54px;
        height: 54px;
        background-color: var(--primary);
        right: 0;
        top: 0;
        background-color: var(--primary);
        clip-path: polygon(0 0, 2px 0, 100% calc(100% - 4px), 100% 100%, calc(100% - 0px) 100%, 0 2px);
    }
    &:first-child {
        // border-right: none;
    }
    &:last-child {
        clip-path: polygon(0 0, 97% 0, 100% 54px, 100% 100%, 0 100%, 0 44%);
        .journal-title {
            h4 {
                max-width: 395px;
            }
        }
    }

    .journal-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 50px;
        h4 {
            font-weight: 700;
            font-size: 26px;
            line-height: 110%;
            text-transform: uppercase;
            font-family: var(--playsans-bulky);
            max-width: 560px;
            width: 100%;
        }
        span {
            display: block;
            font-weight: 400;
            font-size: 18px;
            line-height: 122.5%;
            text-transform: uppercase;
        }
    }
    .thumb {
        overflow: hidden;
        img {
            transition: all .7s ease-in-out;
            transform: scale(1);
        }
    }
    &:hover {
        .thumb {
            img {
                transform: scale(1.05);
            }
        }
    }
}

// Responsive 
@media screen and (max-width: 991px) {
    .single-journal {
        .journal-title {
            margin-bottom: 30px;
            h4 {
                font-size: 20px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .journal-wrapper {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .single-journal {
        padding: 20px;
        &::before {
            width: calc(100% - 20px);
        }
        &::after {
            width: 20px;
            height: 20px;
        }
        &:first-child {
            border-right: 1px solid var(--primary);
            clip-path: polygon(0 0, 97% 0, 100% 20px, 100% 100%, 0 100%, 0 44%);
        }
        &:last-child {
            clip-path: polygon(0 0, 97% 0, 100% 20px, 100% 100%, 0 100%, 0 44%);
        }
        .journal-title {
            h4 {
                font-size: 16px;
            }
        }
    }
}
@media screen and (max-width: 575px) {
    .journal-area {
        border-top: 1px solid var(--primary);
    }
    .single-journal {
        border: 1px solid var(--primary);
        border-top: none;
        &::before {
            height: 1px;
        }
        &::after {
            top: -1px;
            clip-path: polygon(0 0, 1px 0, 100% calc(100% - 2px), 100% 100%, calc(100% - 0px) 100%, 0 1px);
        }
    }
    .single-journal:last-child,
    .single-journal:first-child {
        clip-path: polygon(0 0, 96% 0, 100% 19px, 100% 100%, 0 100%, 0 44%);
    }
}