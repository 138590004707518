
:root {
    --playsans-neutral: 'PolySans Neutral';
    --playsans-median: 'PolySans Median';
    --playsans-slim: 'PolySans Slim';
    --playsans-bulky: 'PolySans Bulky';
}

//font weight variable
:root {
    --black-bold: 900;
    --extra-bold: 800;
    --bold: 700;
    --semi-bold: 600;
    --medium: 500;
    --light: 300;
    --regular: 400;
}


:root {
    // Color 
    --white: #fff;
    --primary: #FFBA08;
}

