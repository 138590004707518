
.get-in-touch-area {
    margin-top: 20px;
    
}

.get-in-touch-wrapper {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 142px;
        height: 142px;
        top: 0;
        right: 0;
        z-index: 1;
        background-color: var(--primary);
        clip-path: polygon(0 0, 1px 0, 100% calc(100% - 2px), 100% 100%, calc(100% - 2px) 100%, 0 2px);
    }
    .shape {
        position: absolute;
        content: "";
        right: 0px;
        top: -50px;
        animation: cliLeftRight 1s linear infinite alternate;
    }
    &::after {
        width: 16px;
        height: 16px;
        background-color: var(--primary);
        position: absolute;
        content: "";
        bottom: 0px;
        left: 0;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
    }
}
.get-in-touch-wrap {
    border: 2px solid var(--primary);
    padding: 40px;
    clip-path: polygon(calc(100% - 142px) 0, 100% 142px, 100% 100%, 0 100%, 0 0);
}

.get-in-touch-title {
    display: flex;
    align-items: center;
    gap: 40px;
    max-width: 861px;
    width: 100%;
    position: relative;
    padding-bottom: 16px;
    > img {
        animation: rotate 5s linear infinite;
    }
    .content-inner {
        display: flex;
        align-items: center;
        gap: 40px;
        h2 {
            font-weight: 400;
            font-size: 50px;
            line-height: 97%;
            text-transform: uppercase;
            span {
                display: block;
                margin-left: 65px;
            }
        }
        p {
            font-weight: 400;
            font-size: 22px;
            line-height: 130%;
            letter-spacing: -0.019em;
            max-width: 406px;
        }
    }
    .underline {
        position: absolute;
        left: 0;
        bottom: 0;
        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.get-in-touch-mail-wrap {
    position: relative;
    margin-top: 30px;
    display: inline-block;
    padding: 0px 10px 12px;
    span {
        position: absolute;
        content: "";
        width: 15px;
        height: 100%;
        top: 0;
        border-top: 2px solid var(--primary);
        border-bottom: 2px solid var(--primary);
    }
    .get-in-tuch-left-shape {
        left: 0;
        border-left: 2px solid var(--primary);
        border-radius: 8px 0px 0px 8px;
        clip-path: polygon(100% 15px, 0 15px, 0 0, 16% 0, 88% 0, 100% 0, 100% 100%, 78% 100%, 4% 100%, 0 100%, 0 calc(100% - 16px), 41% 91%);
    }
    .get-in-tuch-right-shape {
        right: 0;
        border-right: 2px solid var(--primary);
        border-radius: 0px 8px 8px 0px;
        clip-path: polygon(100% 0, 100% 15px, 84% 15px, 77% calc(100% - 15px), 100% calc(100% - 15px), 114% 100%, 0 100%, 0 0);
    }
}
.get-in-touch-mail {
    display: block;
    font-size: calc(20px + 8.5vw);
    font-family: var(--playsans-bulky);
    font-weight: 700;
    line-height: 1;
}

@media screen and (max-width: 1575px) {
    .get-in-touch-mail {
        font-size: calc(20px + 8vw);
        display: inline-block;
    }
}
@media screen and (max-width: 1350px) {
    .get-in-touch-mail {
        font-size: calc(20px + 7vw);
    }
}
@media screen and (max-width: 991px) {
    .get-in-touch-mail {
        font-size: calc(20px + 6vw);
    }
    .get-in-touch-title {
        align-items: flex-start;
        .content-inner {
            align-items: flex-start;
            gap: 20px;
            flex-direction: column;
            position: relative;
        }
    }
    .get-in-touch-wrapper {
        &::before {
            width: 50px;
            height: 50px;
        }
    }
    .get-in-touch-wrap {
        clip-path: polygon(calc(100% - 50px) 0, 100% 50px, 100% 100%, 0 100%, 0 0);
    }
}
@media screen and (max-width: 767px) {
    .get-in-touch-mail {
        font-size: calc(20px + 5vw);
    }
    .get-in-touch-title {
        .content-inner {
            h2 {
                font-size: 30px;
                span {
                    margin-left: 40px;
                }
            }
            p {
                font-size: 18px;
            }
        }
    }
}
@media screen and (max-width: 575px) {
    .get-in-touch-wrap {
        padding: 20px;
    }
    .get-in-touch-title {
        gap: 20px;
        > img {
            width: 50px;
        }
        .content-inner {
            h2 {
                font-size: 24px;
                line-height: 1.2;
                span {
                    margin-left: 20px;
                }
            }
        }
    }
    .get-in-touch-mail {
        font-size: 8vw;
    }
    .get-in-touch-mail-wrap {
        padding: 10px 10px;
        span {
            width: 10px;
        }
        .get-in-tuch-left-shape {
            clip-path: polygon(100% 10px, 0 10px, 0 0, 16% 0, 88% 0, 100% 0, 100% 100%, 78% 100%, 4% 100%, 0 100%, 0 calc(100% - 10px), 41% 91%);
        }
        .get-in-tuch-right-shape {
            clip-path: polygon(100% 0, 100% 10px, -88% 10px, 0 calc(100% - 10px), 100% calc(100% - 10px), 100% 100%, 0 100%, 0 0);
        }
    }
    .get-in-touch-wrapper .shape {
        top: -40px;
        width: 30px;
    }
    .get-in-touch-wrap {
        border: 1px solid var(--primary);
    }
    .get-in-touch-wrapper {
        &::before {
            clip-path: polygon(0 0, 1px 0, 100% calc(100% - 1px), 100% 100%, calc(100% - 1px) 100%, 0 1px);
        }
    }
}