
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes downRight {
    0%, 100% { transform: translateX(0) translateY(0); }
    50% { transform: translateX(20px) translateY(20px); }
}
@keyframes spinAndScale {
    0% { transform: rotate(0deg) scale(1.0); }
    10% { transform: rotate(10deg) scale(1.1); }
    50% { transform: rotate(180deg) scale(1.2); }
    100% { transform: rotate(360deg) scale(1.0); }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes downMoving {
    from {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
@keyframes downMovingReverse {
    from {
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
@keyframes downMovingLeftRight {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
    50% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
}
@keyframes animateHeart {
    // scale down and scale up faster in irregular intervals to get the throbbing effect
    0% {
      transform: scale(0.8);
    }
    5% {
      transform: scale(0.9);
    }
    10% {
      transform: scale(0.8);
    }
    15% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(0.8);
    }
}
@keyframes zoomInOut {
    from {
        transform: scale(.9);
    }
    to {
        transform: scale(1);
    }
}
@keyframes upsDown {
    from {
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
@keyframes cliLeftRight {
    from {
        transform: translateX(-20px);
    }
    to {
        transform: translateX(0px);
    }
}
@keyframes waviy {
    0%,40%,100% {
      transform: translateY(0)
    }
    20% {
      transform: translateY(-15px)
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
  
    35% {
      -webkit-transform: scale(0.95);
      -ms-transform: scale(0.95);
      transform: scale(0.95);
    }
  
    60% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
  
    100% {
      -webkit-transform: scale(0.95);
      -ms-transform: scale(0.95);
      transform: scale(0.95);
    }
  }

  @keyframes downMoving2 {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    50% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}


.dotAnimaton {
    animation: dotAnimaton 5s infinite;
    animation-delay: calc(.10s * var(--i));
}

@keyframes dotAnimaton {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}